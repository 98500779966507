#cookie-bar {
  background:#111111;
  height:auto;
  line-height:24px;
  color:#eeeeee;
  text-align:center;
  padding:8px 0;
  font-family:'Roboto';
  font-weight:300;
  &.fixed {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    &.bottom {
      bottom:0;
      top:auto;
      z-index:999999;
    }
  }
  p {
    margin:0;
    padding:0;
  }
  a {
    color:#ffffff;
    display:inline-block;
    border-radius:3px;
    text-decoration:none;
    padding:0 6px;
    margin-left:8px;
  }
  .cb-enable {
    background:#007700;
    &:hover {
      background:#009900;
    }
  }
  .cb-disable {
    background:#990000;
    &:hover {
      background:#bb0000;
    }
  }
  .cb-policy {
    background:#0033bb;
    &:hover {
      background:#0055dd;
    }
  }
}
