.header {
  background: #ffffff;
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:100;
  padding:rem-calc(20 0);
  border-bottom:rem-calc(1) solid #f5f5f5;
  &.shadow-header {
      box-shadow:0 rem-calc(4) rem-calc(32) 0 rgba(0,0,0,0.15);
  }
  .top-menu {
    display:flex;
    align-items: center;
    .logo {
      line-height:1;
      font-weight:900;
      font-size:rem-calc(22);
      img {
        max-height:rem-calc(80);
        width:auto;
      }
      a {
        color:#5053AB;
        span {
          background:#EF59AC;
          color:#fff;
          line-height:0;
          font-size:rem-calc(14);
          position:relative;
          padding:rem-calc(0 10);
          top:rem-calc(-2);
          left:rem-calc(5);
          border-radius:rem-calc(2);
        }
      }
    }
    .header-menu {
      margin-left:auto;
      .offcanvas {
        padding:rem-calc(15 30);
      }
      .search {
        margin-bottom:rem-calc(20);
        form {
          position:relative;
          input {
            width:100%;
            height:rem-calc(40);
            border:2px solid #e5e5e5;
            border-radius:rem-calc(4);
            padding:rem-calc(0 20);
            &:focus {
              border:2px solid #ccc;
              outline:none;
            }
          }
          button {
            background:none;
            position:absolute;
            right:0;
            top:0;
            border:none;
            height:rem-calc(40);
            width:rem-calc(40);
            display:flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .header-menu-wrapper {
        display:flex;
        flex-direction:column;
        align-items: flex-end;
        .search {
          display:flex;
          justify-content: flex-end;
          margin-bottom:0;
          form {
            width:rem-calc(300);
            input {
              width:rem-calc(300);
            }
          }
        }
        .menu {
          > ul {
            list-style:none;
            margin:0;
            padding:0;
            > li {
              display:inline-block;

              > a {

                color:#5053AB;
                line-height:rem-calc(60);
                display:block;
                font-weight:700;
                padding:rem-calc(0 15);
                &:focus {
                  background:none;
                }
                .badge {
                  padding:rem-calc(2 7);
                  height:rem-calc(20);
                  border-radius:rem-calc(3);
                  text-align:center;
                  display:inline-block;
                  position:relative;
                  top:rem-calc(-2);
                }
              }

              &.has-submenu {
                position:relative;
                ul {
                  position:absolute;
                  display:none;
                  width:rem-calc(170);
                  background:#fff;
                  border:2px solid #eee;
                  left:0;
                  list-style:none;
                  margin:0;
                  padding:rem-calc(5 15);
                  border-radius:rem-calc(7);
                  li {
                    a {
                      color:#5053AB;
                      display:block;
                      font-weight:700;
                      padding:rem-calc(3 0);
                      line-height:rem-calc(30);
                    }
                  }

                }
                &:hover {
                  ul {
                    display:block;
                  }
                }
              }
              &:last-child {
                a {
                  padding-right:0;
                }
              }
            }
          }
        }
      }
      .categories-toggle {
        color:#5053AB;
        line-height:rem-calc(40);
        display:block;
        font-weight:700;
        &:focus {
          background:none;
        }
      }
      .categories-menu {
        list-style:none;
        margin:0;
        padding:rem-calc(0 0 0 15);
        display:none;
        &.menu-expanded {
          display:block;
        }
        li {
          a {
            line-height:rem-calc(40);
            display:block;
            font-weight:400;
          }
        }
      }
      .user-menu {
        list-style:none;
        margin:0;
        padding:0;
        li {
          a {
            color:#5053AB;
            line-height:rem-calc(40);
            display:block;
            font-weight:700;
          }
        }
      }
    }
  }
}
